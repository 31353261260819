import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          <p>
            Discover our portfolio of bespoke event creations, from innovative display designs to immersive event setups. Each project reflects our commitment to creativity and precision, showcasing the memorable experiences we've brought to life.
          </p>
        </div>
        <div className="row portfolio-items">
          {props.data ? (
            props.data.map((d, i) => (
              <div
                key={`${d.title}-${i}`}
                className="col-xs-12 col-sm-6 col-md-4 portfolio-item"
              >
                <div className="hover-bg">
                  <LazyLoadImage
                    alt={d.title}
                    src={d.smallImage}
                    className="img-responsive"
                    effect="blur"
                  />
                  <div className="hover-text">
                    <h4>{d.title}</h4>
                  </div>
                </div>
              </div>
            ))
          ) : (
            "Loading..."
          )}
        </div>
      </div>
    </div>
  );
};