import React from "react";

export const Testimonials = (props) => {
  return (
    <div id="testimonials">
      <div className="mission-cont">
        <div className="mission-div">
          <div className="section-title text-center">
            <h2 className="title-mission">Mission</h2>
          </div>
          <h3 className="mission-paragraphe">
            Our mission is to serve our customers by delivering innovative event and branding solutions. We operate in global markets, utilizing the latest technology to stay at the forefront of creativity. We are dedicated to sustainable growth and profitability, guided by our core values of creativity and excellence. Our competitive edge is our ability to transform ideas into impactful experiences. We are committed to social responsibility and believe in fostering a positive work environment for our employees.
          </h3>
          <div className="icon-mission">
            <i className="fas fa-bullseye fa-3x"></i>
          </div>
          <div className="decor-mission">
          </div>
        </div>
        <div className="vision-div">
          <div className="section-title text-center">
            <h2 className="title-vision">Vision</h2>
          </div>
          <h3 className="vision-paragraphe">
            Our vision is to be the leading creative agency that transforms ideas into innovative experiences, setting new standards in design and event management while inspiring creativity and excellence across the industry.
          </h3>
          <div className="icon-vision">
            <i className="fas fa-rocket fa-3x"></i>
          </div>
        </div>
      </div>
    </div>
  );
};