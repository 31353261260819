import React, {useEffect } from "react";

export const Features = (props) => {
  useEffect(() => {
    const handleScroll = () => {
      const featuresSection = document.getElementById("features");
      const featureIcons = featuresSection.querySelectorAll(".feature-box");

      featureIcons.forEach((icon) => {
        const rect = icon.getBoundingClientRect();
        const inView = rect.top <= window.innerHeight && rect.bottom >= 0;

        if (inView) {
          icon.classList.add("in-view");
        } else {
          icon.classList.remove("in-view");
        }
      });
    };

    const scrollHandler = () => {
      window.requestAnimationFrame(handleScroll);
    };

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Features</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
                  <div className="feature-box">
                    <i className={d.icon}></i>
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};