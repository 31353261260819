import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="video-container">
        {/* Add the video element */}
        <video autoPlay muted loop playsInline className="background-video">
          <source src="../img/Video1.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="overlay"></div>

      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 intro-text">
              <h1>
                {props.data ? props.data.title : "Loading"}
                <span></span>
              </h1>
              <p>{props.data ? props.data.paragraph : "Loading"}</p>
              <a
                href="#features"
                className="btn btn-custom btn-lg page-scroll"
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
